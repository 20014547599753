import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 45 min. `}<meta itemProp="prepTime" content="PT45M" /></h5>
    <h5>{`Cook time: 70 min. `}<meta itemProp="cookTime" content="PT70M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Whole grain penne, 1 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 4 cloves minced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Red pepper flakes, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Tomato paste, 3 oz.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Frozen chopped spinach, 20 oz.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Canned diced tomatoes, 28 oz.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Basil, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parsley, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Oregano, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Thyme, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan, 1/4 cup grated</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Cheese, 2 cups shredded (Cabot New York Extra Sharp Cheddar)
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Ricotta cheese, 1.5 cups</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Medium pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Large pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Colanders, 2</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">9x13 glass pan</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Defrost and drain frozen spinach in colander</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate parmesan</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate cheese</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Mince garlic</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Bring medium pot of water to boil. Cook pasta until 2 minutes short of al dente. Drain and set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Preheat oven to 400F.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`In heated large pot, cook olive oil, red pepper flakes, and garlic until fragrant, about 1 minute.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add tomato paste and stir.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add spinach and cook until wilted, about 3 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add diced tomatoes and stir.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add basil, parsley, oregano, thyme, salt, and pepper.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add parmesan and stir until melted.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cook sauce, continue to stir, at least 10 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Turn off heat, stir in pasta until well mixed.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Transfer half of pasta to glass pan.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Layer on scoops of ricotta over pasta.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cover with remaining pasta.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Sprinkle with cheese.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bake until bubbling, 20-25 minutes. Let rest 10-15 minutes before serving.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      